import React, { useState, useEffect } from "react"
import Header from "../../../components/Yard/common/Header"
import "./Wash_Detail.css"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import AmountContainer from "./comp/AmountContainer"
import ServiceSelect from "../wash_details/comp/ServiceSelect"
import TextArea from "../../../components/Yard/textArea/TextArea"
// import Button from "../../../components/Yard/Button/Button"
import DateTime from "../../../components/Yard/dateTime/DateTime"
import PictureCard from "../../../components/upload/PictureCard"
import { message, Radio, Button, Modal } from "antd"
import moment from "moment"
import API from "../../../api"
import { Routes } from "../../../utils/route"
import { Spin } from "antd"
import { getTicketDetails } from "../../../actions/myYardAction"
import { removeYardTicketAfterComplete } from "../../../actions/yardActions/YardActions"
import { browserName } from "react-device-detect"
import YardDetails from "../../../components/data-display/card/YardDetails"
import ElpsImages from "./comp/ElpsImages"
import WashBox from "./comp/WashBox"
import SafetyBox from "./comp/SafetyBox"
import { IndustryType } from "../../../utils/helper"
import Head from "../../../components/Yard/header/Header"
import EquipmentDet from "../equipment/comp/EquipmentDet"
import EquipmentDoc from "../equipment/comp/EquipmentDoc"
import Certificate from "../../../pages/myYard/certificate/index"
import TicketSubmit from "../../../components/ticketSubmit/TicketSubmit"
import { EditOutlined, ArrowLeftOutlined } from "@ant-design/icons"

const WashDetail = props => {
  const { updateCertificateNumber, yardTicket } = API
  const id = props.id

  const [service, setService] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const { ticketCompleteYard, fetchServiceDetailsPost } = API
  const [startTime, setStartTime] = useState(null)
  const [btnDisable, setBtnDisable] = useState(false)
  const [serviceData, setServiceData] = useState([])
  const [certificate, setCertificate] = useState(false)
  const [invoice, setinvoice] = useState(null)
  const [ticket, setTicket] = useState(null)
  const [ticketShow, setTicketShow] = useState(true)
  const [nextTicket, setNextTicket] = useState(null)
  const [edit, setEdit] = useState(false)
  const [load, setLoad] = useState(true)
  const [error, setError] = useState("")
  useEffect(() => {
    if (typeof window != undefined) {
      setStartTime(localStorage.getItem("startTime"))
    }
  }, [])
  const [state, setState] = useState(null)
  const [text, setText] = useState("")
  const isChrome =
    browserName === "Chrome"
      ? "YYYY-MM-DD"
      : browserName === "Safari"
      ? "MM-DD-YYYY"
      : "YYYY-MM-DD"

  const [date, setDate] = useState(moment().format(isChrome))
  const [time, setTime] = useState(moment().format("HH:mm"))
  const [image, setImage] = useState([])
  const [gal, setGal] = useState(null)
  const [drum, setDrum] = useState(null)
  const [solvent, setSolvent] = useState(null)
  const [deodorizer, setDeodorizer] = useState(null)
  const [radioValue, setRadioValue] = useState(null)
  const [washTime, setWashTime] = useState(
    new Date(moment().format("MM/DD/yyyy HH:mm"))
  )
  // const [serviceData,setServiceData]=useState(service)
  const [ticketDetailsData, setTicketDetailsData] = useState(null)
  const [serviceGroupId, setServiceGroupId] = useState(null)
  const [safety, setSafety] = useState([{ url: "", code: "", remark: "" }])
  useEffect(() => {
    const callback = val => {
      if (val === true) {
        fetchServices()
        setLoading(false)
        if (props.yardHandData) {
          filterEquipmentDetails()
        }
      }
    }
    Routes("yard", callback)
  }, [])

  const filterEquipmentDetails = () => {
    const filteredEquipment = props.yardHandData?.filter((val, index) => {
      if (val.ticket_ref_id == id) {
        return val
      }
    })

    if (filteredEquipment && filteredEquipment.length > 0) {
      setState(...filteredEquipment)
      filteredEquipment &&
        filteredEquipment.map(item => {
          props.getTicketDetails(item?.ticket_id)
        })
      message.success({ content: `FETCHED EQUIPMENT DATA`, duration: 2 })
    }
  }
  useEffect(() => {
    if (props?.location?.state?.service) {
      setService(props?.location?.state?.service)
    }
  }, [])

  const onTicketComplete = async () => {
    setBtnDisable(true)
    const _service = []
    service.map(item => {
      _service.push({
        id: item.id,
        service_id: item.service_id,
        price: item.price,
        tax: item.tax,
        duration: item.duration ?? 0,
        quantity: item.quantity ?? 0,
        temp: item.temp ?? 0,
        final_conductivity: item.final_conductivity ?? 0,
        flow_gpm: item.flow_gpm ?? 0,
        total_flow: item.total_flow ?? 0,
      })
    })
    const ap = moment(`${date} ${time}`).format("HH:mm")
    const dMoment = moment(date).format("MM/DD/yyyy")
    const d = new Date(`${dMoment} ${ap}`)

    const s = moment(parseInt(startTime)).format("MM/DD/YYYY")
    const sd = new Date(`${s}`)
    const details = [
      { key: "wash_time", value: washTime.getTime().toString() },
      { key: "ticket_wash_end_time", value: d.getTime().toString() },
      {
        key: "ticket_complete_remark",
        value: text,
      },
    ]
    if (radioValue !== null) {
      details.push({
        key: "waste_type",
        value: radioValue === 1 ? "SOLID" : "LIQUID",
      })
    }
    try {
      const res = await ticketCompleteYard({
        details: details,
        ticket_id: state?.ticket_id,
        services: _service,
        safety: safety,
      })
      if (res.data.success === true) {
        props.removeYardTicketAfterComplete(state?.ticket_id)
        message.success({
          content: `ticket completed successfully`,
          duration: 2,
        })
      }
      setBtnDisable(false)
      setIsModalVisible(true)
    } catch (e) {
      setBtnDisable(false)
      message.error({ content: `NOT ABLE TO PERFROM ACTION`, duration: 2 })
    }
  }

  const handleChange = (val, type) => {
    switch (type) {
      case "gal":
        setGal(val)
        break
      case "drum":
        setDrum(val)
        break
      case "solvent":
        setSolvent(val)
        break
      case "deodorizer":
        setDeodorizer(val)
        break
      default:
        break
    }
  }
  const fetchServices = () => {
    fetchServiceDetailsPost().then(res => {
      const data = res.data.data
      const filterData = group => {
        const filteredData = data.filter(
          item => item.service_group_id === group
        )
        return filteredData
      }

      setServiceGroupId({
        consumable: filterData(2040),
        disposal: filterData(2050),
        labor: filterData(2060),
        recycle: filterData(2070),
      })
      setServiceData(data)
    })
  }
  useEffect(() => {
    setTicketDetailsData(props.ticketDetailsData)
  }, [props.ticketDetailsData])

  useEffect(() => {
    if (ticketDetailsData && ticketDetailsData.length != 0) {
      setinvoice(ticketDetailsData[0])
      setTicket(
        ticketDetailsData[0]?.certificate_no
          ? ticketDetailsData[0].certificate_no
          : "NA"
      )
      setNextTicket(
        ticketDetailsData[0]?.certificate_no
          ? ticketDetailsData[0].certificate_no
          : "NA"
      )
    }
  }, [ticketDetailsData])

  const ticketWashEdit = () => {
    setTicketShow(false)
  }
  const onChangeTicket = e => {
    setNextTicket(e.target.value)
    if (ticket !== invoice.certificate_no) {
      setError("")
    }
  }
  const ticketWashClose = () => {
    setTicketShow(true)
    setError("")
  }
  const hideEditService = () => {
    setEdit(false)
    setTicketShow(true)
    setNextTicket(
      ticket ? ticket : invoice.certificate_no ? invoice.certificate_no : "NA"
    )
  }
  const editSubmit = () => {
    setEdit(false)
  }
  const showEditService = () => {
    setEdit(true)
  }
  const ticketWashSubmit = () => {
    if (nextTicket === "") {
      setError("* This field is required!")
    } else if (nextTicket === ticket) {
      setError("*Please use an unique number!")
    } else {
      setLoad(false)
      setError("")

      const body = {
        certificateNumber: nextTicket,
      }
      updateCertificateNumber(ticketDetailsData[0].ticket_id, body)
        .then(res => {
          message.success("certificate Number Update")
          // props.getTicketDetails(props.data?.ticket_id || props.data?.ticket_details?.ticket_id)
          // props.getInYardList(props.inYardPage,'');
          // props.getClosedOutList(props.closedOutPage,'')
          setLoad(true)
          setTicket(nextTicket)
          setTicketShow(true)
        })
        .catch(error => {
          setError("*Please use an unique number!")
          setLoad(true)
          message.error("Something went wrong")
        })
    }
  }

  const handleOk = () => {
    setIsModalVisible(false)
    navigate("/yard/home")
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    navigate("/yard/home")
  }
  if (!loading) {
    return (
      <>
        <Head />
        <section className="wash_details d-print-none">
          <Header
            data={state}
            status={props.location?.state?.item}
            headName="add Wash Details"
            LinkName={
              IndustryType() !== "Food_Grade"
                ? `/yard/wash_complete/${id}`
                : `/yard/selectservice/${id}`
            }
          />
          <div className="wash_details_container">
            <EquipmentDet
              services={service}
              data={state}
              wash_Type={true}
              id={id}
              washType={
                <Radio.Group onChange={val => setRadioValue(val)}>
                  <div style={{ display: "flex", marginTop: "1px" }}>
                    <Radio value={1}>Solid</Radio>
                    <Radio value={2}>Liquid</Radio>
                  </div>
                </Radio.Group>
              }
            />
            <EquipmentDoc
              data={state?.asset_txns_details?.asset_washout_docs}
              id={id}
            />
            {service.length < 1 ? (
              ""
            ) : (
              <div className="wash_food_select_ser_ad">
                <div className="wash_food_select_ser_ad_head">
                  <div className="wash_one_test">Service Name</div>
                  <div className="wash_one_test2">Duration</div>
                  <div className="wash_one_test2">
                    Temp F<sup style={{ paddingLeft: "1px" }}>0</sup>
                  </div>
                  <div className="wash_one_test">Final Conductivity</div>
                  <div className="wash_one_test2">Flow GPM</div>
                  <div className="wash_one_test2" style={{ border: "none" }}>
                    Total Flow
                  </div>
                </div>
                {service.map(_service => {
                  return (
                    <WashBox
                      key={_service.id}
                      onChange={(name, e) => (_service[name] = e)}
                      title={_service["service_name.param_description"]}
                      {..._service}
                    />
                  )
                })}
              </div>
            )}
            <div className="wash_details_container" style={{ marginTop: 50 }}>
              {serviceGroupId && (
                <>
                  {serviceGroupId.consumable.length !== 0 && (
                    <ServiceSelect
                      ticketDetailsData={ticketDetailsData}
                      serviceData={serviceData}
                      data={state}
                      name="consumable"
                      parent_id={2040}
                      ticketId={props.id}
                    />
                  )}
                  {serviceGroupId.disposal.length !== 0 && (
                    <ServiceSelect
                      ticketDetailsData={ticketDetailsData}
                      serviceData={serviceData}
                      data={state}
                      name="disposal"
                      parent_id={2050}
                      ticketId={props.id}
                    />
                  )}
                  {serviceGroupId.labor.length !== 0 && (
                    <ServiceSelect
                      ticketDetailsData={ticketDetailsData}
                      serviceData={serviceData}
                      data={state}
                      name="labor"
                      parent_id={2060}
                      ticketId={props.id}
                    />
                  )}
                  {serviceGroupId.recycle.length !== 0 && (
                    <ServiceSelect
                      ticketDetailsData={ticketDetailsData}
                      serviceData={serviceData}
                      data={state}
                      name="recycle"
                      parent_id={2070}
                      ticketId={props.id}
                    />
                  )}
                </>
              )}
            </div>
            <SafetyBox safety={safety} setSafety={setSafety} />
            <div className="wash_crt_certificate_change_set">
              <div className="wash_crt_certificate_change">
                <span className="certificate-bold_change">
                  Wash Certificate Number:{" "}
                </span>
                {ticketShow ? nextTicket : ""}
                {ticketShow ? (
                  <div
                    style={{ cursor: "pointer", marginLeft: "13px" }}
                    className="wash_certificate_ticket_edit"
                    onClick={ticketWashEdit}
                  >
                    <EditOutlined />
                  </div>
                ) : (
                  <TicketSubmit
                    load={load}
                    type="text"
                    name="ticket"
                    value={nextTicket === "NA" ? "" : nextTicket}
                    onChange={e => onChangeTicket(e)}
                    ticketWashSubmit={() => ticketWashSubmit()}
                    ticketWashClose={ticketWashClose}
                    error={error}
                    btnshow={nextTicket === ticket || nextTicket === ""}
                  />
                )}
              </div>
            </div>

            <DateTime
              label_1="date"
              label_2="time"
              date={date}
              time={time}
              setTime={setTime}
              setDate={setDate}
              allow={true}
            />
            {btnDisable ? (
              <div
                className="wash_washDetails_ty"
                style={{ background: "grey", cursor: "not-allowed" }}
              >
                mark ticket complete
              </div>
            ) : (
              <div
                className="wash_washDetails_ty"
                onClick={() => onTicketComplete()}
              >
                mark ticket complete
              </div>
            )}
            <Modal
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Certificate
                    visible={certificate}
                    setVisible={e => setCertificate(e)}
                    data={ticketDetailsData[0]}
                    {...props}
                    Button={<span>Yes</span>}
                    title="Certificate Of Washout"
                  />
                  <Button
                    key="back"
                    style={{
                      background: "#FD8F46",
                      color: "#fff",
                      width: "210px",
                    }}
                    onClick={handleCancel}
                  >
                    No
                  </Button>
                </div>,
              ]}
            >
              <div className="wash_ticket_and_cert">ticket completed</div>
              <h4 style={{ padding: "35px 0px 4px 0px" }}>
                {" "}
                Review and Print Certificate?
              </h4>
            </Modal>
          </div>
        </section>
      </>
    )
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  yardHandData: state.yardHandData,
  ticketDetailsData: state.myYard.ticketDetails,
})
export default connect(mapStateToProps, {
  removeYardTicketAfterComplete,
  getTicketDetails,
})(WashDetail)
